import React, {ChangeEvent} from 'react';
import classNames from 'classnames';
import styles from './InputFields.module.scss';
interface IInputFields{
    light?: boolean;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    value?: string;
    type?: string;
    placeholder?: string;
    className?: string;
}
const InputFields = ({light, className, ...props}: IInputFields):JSX.Element => {

    const inputFieldClasses = classNames(styles.inputFields, className, {
        [styles.light]: light,
    });

    return <input className={inputFieldClasses} {...props} />;
};

export default InputFields;