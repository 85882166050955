import React from 'react';
import styles from './RequestResponseModal.module.scss';
import {Message, Button} from 'components';
import {md} from '../../consts/Breakpoints';
import useWindowSize from '../../hooks/useWindowSize';
import Close from '../../assets/icons/Close';
import {black} from '../../consts/Colors';

const illustration = `${process.env.REACT_APP_CLOUDFRONT_URI}/Illustrations/ExitModal/exit-modal.png`;
const illustrationSm = `${process.env.REACT_APP_CLOUDFRONT_URI}/Illustrations/ExitModal/Small_illustration_3.png`;

interface IModalProps {
    closeModal: () => void;
    updateSessionHandler: () => void;
}

const Modal: React.FC<IModalProps>= ({closeModal, updateSessionHandler}) => {
    const {width} = useWindowSize();

    return (
        <div className={styles.modal}>
            <img alt="figure" src={width > md ? illustration : illustrationSm} className={styles.illustration} />
            <div className={styles.wrapperContent}>
                {/* <div className={styles.comment}>
                    <Message>
                        <p className={styles.text}>Looks like there&apos;s a few unanswered questions remaining.</p>
                    </Message>
                </div> */}
                <div className={styles.newWrapperContent}>
                    <h4 className="title mb-20 ">Are you sure you want to continue?</h4>
                    <p className="description-sm mb-30">
                        There are some unanswered questions remaining.
                    </p>
                    <div className={styles.actionWrapper}>
                        <div className='mr-20'>
                            <Button className={styles.leaveBtn} onClick={closeModal}>Return</Button>
                        </div>
                        <Button onClick={updateSessionHandler}>Continue</Button>
                    </div>
                </div>
            </div>
            <div className={styles.close} onClick={closeModal}><Close fill={black}/></div>
        </div>
    );
};

export default Modal;