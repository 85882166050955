import React from 'react';
import Container from '../Container';
import classNames from 'classnames';
import Paths from '../../consts/Paths';
import UILink from 'components/Link';
import style from './Footer.module.scss';
import { useAppSelector } from '../../redux/hooks';
import { selectContent } from '../../redux/slices/contactInfo/selectors';
import { EContentKeys } from '../../consts/ContentKeys';

interface IFooterBottomLineProps {
    background?: string;
    color?: 'black' | 'slateBlue';
    survey?: boolean;
    overrideButtonClicks?: () => void;
    padding?: string;
}

const FooterBottomLine = ({ survey, color, overrideButtonClicks, padding, ...props }: IFooterBottomLineProps): JSX.Element => {
    const pageContent = useAppSelector(selectContent)[EContentKeys.FOOTER_PAGE_KEY];

    const classes = {
        [style.black]: color === 'black',
        [style.slateBlue]: color === 'slateBlue',
    };

    const getHref = (href: string) => {
        return overrideButtonClicks ? '' : href;
    };

    return (
        <div style={{ ...props }} className={classNames(style.footerBottomLine, classes, 'subtitle-sm')}>
            <Container padding={padding} survey={survey}>
                <div className={style.bottomLineBox}>
                    <span className={style.copyrightWrapper}>{pageContent.Body}</span>
                    <span className={style.navWrapper}>
                        <div>
                            <UILink
                                target="_blank"
                                className={style.privacyPolicy}
                                underlineColor={color}
                                href={getHref(Paths.privacyPolicy)}>
                                Privacy Policy
                            </UILink>
                        </div>
                        <div>
                            <UILink
                                target="_blank"
                                underlineColor={color}
                                href={getHref(Paths.cookies)}>
                                Cookies
                            </UILink>
                        </div>
                    </span>
                </div>
            </Container>
        </div>

    );
};

export default FooterBottomLine;
