// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ShowMoreBtn_showMoreBtn__NUu-t {
  color: #365479;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
}
.ShowMoreBtn_showMoreBtn__NUu-t svg {
  transition: 0.2s all linear;
  margin-left: 10px;
}
.ShowMoreBtn_showMoreBtn__NUu-t.ShowMoreBtn_active__TSd4a svg {
  transform: rotate(-180deg);
}`, "",{"version":3,"sources":["webpack://./src/components/Buttons/ShowMoreBtn/ShowMoreBtn.module.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAEA;EACE,cCoBU;EDnBV,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,UAAA;AADF;AAEE;EACE,2BAAA;EACA,iBAAA;AAAJ;AAGE;EACE,0BAAA;AADJ","sourcesContent":["@import \"src/styles/variables\";\n\n.showMoreBtn{\n  color: $slateBlue;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  z-index: 1;\n  svg{\n    transition: 0.2s all linear;\n    margin-left: 10px;\n  }\n\n  &.active svg{\n    transform: rotate(-180deg);\n  }\n}","$grid-breakpoints: (\r\n        xs: 350px,\r\n        ms: 426px,\r\n        sm: 576px,\r\n        md: 768px,\r\n        lg: 992px,\r\n        xl: 1200px,\r\n        xxl: 1440px,\r\n        xxxl: 1581px\r\n);\r\n\r\n$black: #161d1b;\r\n$gray: #bebeb576;\r\n$gray1: #6F7172;\r\n$gray2: #BEBEBE;\r\n$gray3: #E5E8EB;\r\n$grayBg: #E6E6E6;\r\n$steel: #d1d5db;\r\n$waterGreen: #c8d9cf;\r\n$white: #f4f7f6;\r\n$whiteBg: #f6f6f6;\r\n$orange: #FFAE11;\r\n$yellow: #e5de35;\r\n$slateBlue: #365479;\r\n$blue: #4789d7;\r\n$burntOrange: #b93d22;\r\n$green: #4a7b75;\r\n$seaGreen: #C8D9CF;\r\n$cream: #FAECD2;\r\n\r\n$lightOrange: #fff6e5;\r\n$lightBlue: #eaf2fa;\r\n$lightWaterGreen: #f0f5f2;\r\n$lightSlateBlue: #edf2f7;\r\n$lightSteel: #f1f2f4;\r\n$lightBurntOrange: #fbede9;\r\n$lightGreen: #eff5f5;\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"showMoreBtn": `ShowMoreBtn_showMoreBtn__NUu-t`,
	"active": `ShowMoreBtn_active__TSd4a`
};
export default ___CSS_LOADER_EXPORT___;
