import React from 'react';
import styles from './Pupup.module.scss';
import cx from 'classnames';

interface PropTypes {
    isVisible: boolean;
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
    children: React.ReactNode;
}

const Popup: React.FC<PropTypes> = ({children, onClick, isVisible}) => {

    return (
        <div onClick={onClick} className={cx(styles.wrapperModal, {[styles.active]: isVisible})}>
            <div className={cx(styles.childrenAnimationBox)}>
                {children}
            </div>
        </div>
    );
};

export default Popup;
