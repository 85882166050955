import React from 'react';
import {IIconProps} from '../../../../types';
import {black, white as whiteColor} from '../../../../consts/Colors';

const ProjectLogoSm = ({white}:IIconProps) => {
    return (
        <svg width="45" height="40" viewBox="0 0 45 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4989_2093)">
                <path d="M20.2546 0H0.388217C0.173811 0 0 0.174434 0 0.389609V4.07247C0 4.28763 0.173811 4.46206 0.388217 4.46206H20.2546C20.469 4.46206 20.6428 4.28763 20.6428 4.07247V0.389609C20.6428 0.174434 20.469 0 20.2546 0Z"
                    fill={white ? whiteColor : black}
                />
                <path d="M44.5496 8.76465H0.388217C0.173811 8.76465 0 8.93908 0 9.15426V12.6777C0 12.8929 0.173812 13.0674 0.388219 13.0674H44.5496C44.7639 13.0674 44.9379 12.8929 44.9379 12.6777V9.15426C44.9379 8.93908 44.7639 8.76465 44.5496 8.76465Z"
                    fill={white ? whiteColor : black}
                />
                <path d="M40.7385 17.6885H0.388217C0.173811 17.6885 0 17.8629 0 18.0781V21.6016C0 21.8167 0.173813 21.9913 0.388219 21.9913H40.7385C40.953 21.9913 41.1268 21.8167 41.1268 21.6016V18.0781C41.1268 17.8629 40.953 17.6885 40.7385 17.6885Z"
                    fill={white ? whiteColor : black}
                />
                <path d="M25.4946 26.2949H0.388217C0.173811 26.2949 0 26.4695 0 26.6846V30.3674C0 30.5827 0.173811 30.7571 0.388217 30.7571H25.4946C25.7091 30.7571 25.8829 30.5827 25.8829 30.3674V26.6846C25.8829 26.4695 25.7091 26.2949 25.4946 26.2949Z"
                    fill={white ? whiteColor : black}
                />
                <path d="M22.1601 35.5381H0.388217C0.173811 35.5381 0 35.7125 0 35.9278V39.6106C0 39.8256 0.173812 40.0002 0.388219 40.0002H22.1601C22.3744 40.0002 22.5482 39.8256 22.5482 39.6106V35.9278C22.5482 35.7125 22.3744 35.5381 22.1601 35.5381Z"
                    fill={white ? whiteColor : black}
                />
            </g>
            <defs>
                <clipPath id="clip0_4989_2093">
                    <rect width="45" height="40" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default ProjectLogoSm;
