import { configureStore } from '@reduxjs/toolkit';
import surveyReducer from '../slices/survey';
import globalReducer from '../slices/global';
import faqReducer from '../slices/faq';
import teamReducer from '../slices/team';
import subscribeReducer from '../slices/subscription';
import heroesReducer from '../slices/heroes';

export const store = configureStore({
    reducer: {
        global: globalReducer,
        survey: surveyReducer,
        faq: faqReducer,
        team: teamReducer,
        subscribe: subscribeReducer,
        heroes: heroesReducer
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
