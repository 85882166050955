import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Heroes, IRejectValue } from 'models/heroes';
import HeroesApi from '../../../api/heroesApi';
import errorHandler from '../../../utils/errorHandler';

const heroesApi = new HeroesApi();

interface HeroesState {
    heroes: Heroes;
}

const initialState: HeroesState = {
    heroes: {
        message: '',
        data: [{
            id: '',
            isActive: true,
            backGroundName: '',
            url: ''
        }]
    }
};

export const getHeroes = createAsyncThunk<Heroes, void , IRejectValue>('/ClientSliderImage/getall',
    async (_, { rejectWithValue }) => {
        try {
            const response = await heroesApi.getHeroesList();
            return response;
        } catch (e) {
            return rejectWithValue(errorHandler('Get Heroes List', e));
        }
    }
);

export const slice = createSlice({
    name: 'hero',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getHeroes.fulfilled, (state, { payload }) => {
            state.heroes = payload;
        });
    },
});

export default slice.reducer;