import {createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import errorHandler from '../../../utils/errorHandler';
import SubscribeApi from '../../../api/subscribeApi';
import {IRejectValue} from '../../../models/survey';
import {ICreateResponse, ICreate} from '../../../api/subscribeApi/types';

const subscribeApi = new SubscribeApi();

interface SubscribeState {
    subscribe: ICreate;
}

const initialState: SubscribeState = {
    subscribe: {} as ICreate,
};


export const createSubscription = createAsyncThunk<ICreateResponse, ICreate, IRejectValue>(
    'subscriber/create',
    async (data, {rejectWithValue}) => {
        try {
            return await subscribeApi.createSubscription(data);
        } catch (e) {
            return rejectWithValue(errorHandler('Update Session', e));
        }
    }
);

export const slice = createSlice({
    name: 'subscribe',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(createSubscription.fulfilled, (state, { payload }) => {
            state.subscribe = { email: payload.message };
        });
    },
});

export default slice.reducer;
