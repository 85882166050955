import axios, {AxiosInstance} from 'axios';
import { getFromLocalStorage } from 'utils/common';

export default class BaseApi {
    protected baseApi: AxiosInstance;
    constructor() {
        this.baseApi = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
        });
        this.baseApi.interceptors.request.use((config) => {
            if (config.headers) {
                config.headers = {
                    'auth': process.env.REACT_APP_AUTH_KEY,
                    'Authorization': `Bearer ${getFromLocalStorage('token')}`,
                };
            }
            return config;
        });
    }
}