import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { FAQ, IRejectValue } from 'models/faq';
import FaqApi from '../../../api/faqApi';
import errorHandler from '../../../utils/errorHandler';

const faqApi = new FaqApi();

interface FAQState {
  faqs: FAQ[];
}

const initialState: FAQState = {
    faqs: [],
};

export const getFaqs = createAsyncThunk<FAQ[], Record<string, any> , IRejectValue>('FAQ/getList',
    async (data, { rejectWithValue }) => {
        try {
            const response = await faqApi.getFaqList(data);
            return response.data.faqs;
        } catch (e) {
            return rejectWithValue(errorHandler('Get Faq List', e));
        }
    }
);

export const slice = createSlice({
    name: 'faq',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getFaqs.fulfilled, (state, { payload }) => {
            state.faqs = payload;
        });
    },
});

export default slice.reducer;
