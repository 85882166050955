import React from 'react';
import {NavLink, NavLinkProps} from 'react-router-dom';
import styles from './Link.module.scss';
import classNames from 'classnames';
interface ILinkProps{
    children: React.ReactNode;
    href: NavLinkProps['to'];
    className?: string;
    underline?: boolean;
    target?: string;
    underlineColor?: 'black' | 'slateBlue';
}
const Link: React.FC<ILinkProps> = ({target, children, href, underline, underlineColor, className}) => {
    const classes = {
        [styles.underline]: underline,
        [styles.underlineBlack]: underlineColor === 'black',
        [styles.underlineBlue]: underlineColor === 'slateBlue',
    };
    return (
        <NavLink target={target} className={classNames(styles.link, className, classes)} to={href} tabIndex={-1}>{children}</NavLink>
    );
};

export default Link;