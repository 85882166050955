import React from 'react';
import classNames from 'classnames';
import styles from './Tag.module.scss';

interface ITagProps{
    blue?: boolean;
    orange?: boolean;
    children: React.ReactNode;
    className?: string;
}

const Tag: React.FC<ITagProps> = ({blue, orange, children, className}) => {

    const classes = classNames(styles.tag, 'subtitle-sm', className, {
        [styles.blue]: blue,
        [styles.orange]: orange,
    });

    return <span className={classes}>{children}</span>;
};

export default Tag;