import {createAsyncThunk} from '@reduxjs/toolkit';
import errorHandler from '../../../utils/errorHandler';
import ContactApi from './../../../api/contactApi';
import {IRejectValue} from '../../../models/survey';
import {ICheckCodeResponse, ICheckUpdate, IContact, ICreateContactResponse, ICheckUpdateResponse, IGetResponse, IUpdateClient, ICheckCodeOrUrl, IContactStatus,IVerifyTokenResponse, IToken} from '../../../api/contactApi/types';

const contactApi = new ContactApi();

export const createContact = createAsyncThunk<ICreateContactResponse, IContact, IRejectValue>(
    'contact/createContact',
    async (data, {rejectWithValue}) => {
        try {
            return await contactApi.createContact(data);
        } catch (e) {
            return rejectWithValue(errorHandler('Update Session', e));
        }
    }
);

export const checkContact = createAsyncThunk<ICheckCodeResponse, ICheckCodeOrUrl, IRejectValue>(
    'contact/createContact',
    async (data, {rejectWithValue}) => {
        try {
            return await contactApi.checkContact(data);
        } catch (e) {
            return rejectWithValue(errorHandler('Update Session', e));
        }
    }
);


export const updateClientContact = createAsyncThunk<ICheckUpdateResponse, ICheckUpdate, IRejectValue>(
    'ClientContact/createContact',
    async (data, {rejectWithValue}) => {
        try {
            return await contactApi.updateClientContact(data);
        } catch (e) {
            return rejectWithValue(errorHandler('Update Session', e));
        }
    }
);

export const updateContact = createAsyncThunk<ICheckUpdateResponse, IUpdateClient, IRejectValue>(
    'Contact/update',
    async (data, {rejectWithValue}) => {
        try {
            return await contactApi.updateContact(data);
        } catch (e) {
            return rejectWithValue(errorHandler('Update Session', e));
        }
    }
);

export const getClient = createAsyncThunk<IGetResponse, any, IRejectValue>(
    'ClientContact/get',
    async ({}, {rejectWithValue}) => {
        try {
            return await contactApi.getClientDetail();
        } catch (e) {
            return rejectWithValue(errorHandler('User Details', e));
        }
    }
);

export const contactStatusUpdate = createAsyncThunk<ICheckCodeResponse, IContactStatus, IRejectValue>(
    'contact/createContact',
    async (data, {rejectWithValue}) => {
        try {
            return await contactApi.updateContactStatus(data);
        } catch (e) {
            return rejectWithValue(errorHandler('Update Session', e));
        }
    }
);

export const verifyReCAPTCHA = createAsyncThunk<IVerifyTokenResponse, IToken, IRejectValue>(
    '/Recaptcha/Verify',
    async (data, {rejectWithValue}) => {
        try {
            return await contactApi.verifyRecaptcha(data);
        } catch (e) {
            return rejectWithValue(errorHandler('Update Session', e));
        }
    }
);