import React, { lazy, Suspense } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import Paths from 'consts/Paths';
import './app.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const AboutPage = lazy(() => import('pages/AboutPage'));
const ConsentForm = lazy(() => import('pages/ConsentForm'));
const Survey = lazy(() => import('pages/Survey'));
const ContactInfo = lazy(() => import('pages/ContactInfo'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const NotFound = lazy(() => import('./pages/NotFound'));
const CookiesPage = lazy(() => import('./pages/Cookies'));
const ResearchPage = lazy(() => import('./pages/ResearchPage'));
const Surveys = lazy(() => import('./pages/SurveysPage'));
const NewsPage = lazy(() => import('./pages/NewsPage'));
const PersonalityTestResult = lazy(() => import('./pages/Result'));

const AppRoutes = () => {
    const location = useLocation();

    const routes = [
        {
            path: Paths.aboutPage,
            element: <AboutPage />,
        },
        {
            path: Paths.contactInfo,
            element: <ContactInfo />,
        },
        {
            path: Paths.privacyPolicy,
            element: <PrivacyPolicy />
        },
        {
            path: Paths.cookies,
            element: <CookiesPage />,
        },
        {
            path: Paths.surveys,
            element: <Surveys />,
        },
        {
            path: Paths.consentForm,
            element: <ConsentForm />,
        },
        {
            path: Paths.survey,
            element: <Survey />
        },
        {
            path: Paths.researchPage,
            element: <ResearchPage />
        },
        {
            path: Paths.news,
            element: <NewsPage />
        },
        {
            path: Paths.resultPage,
            element: <PersonalityTestResult />
        },
        {
            path: Paths.notFound,
            element: <NotFound />
        },
        {
            path: Paths.uniqueUrl,
            element: <ConsentForm />
        },
    ];

    return (
        <AnimatePresence initial={false} exitBeforeEnter={false}>
            <Suspense fallback={<div className='w-100 h-100 lazy-div'></div>}>
                <Routes location={location} key={location.pathname}>
                    {routes.map((setting, index) =>
                        <Route key={index} {...setting} />
                    )}
                    <Route path="*" element={<Navigate to="/404" replace />} />
                </Routes>
            </Suspense>
        </AnimatePresence>
    );
};

export default AppRoutes;
