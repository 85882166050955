import React, {
    CSSProperties,
    ReactElement, ReactNode,
    SyntheticEvent, useMemo
} from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import sty from './Button.module.scss';

interface PropTypes {
  href?: string;
  target?: string;
  form?: string;
  disabled?: boolean;
  actionButton?: boolean;
  secondary?: boolean;
  yellowActionButton?: boolean;
  transparent?: boolean;
  styles?: CSSProperties;
  className?: string;
  children: ReactNode | string;
  htmlType?: 'submit' | undefined;
  iconLeft?: ReactElement;
  iconRight?: ReactElement;
  iconMargin?: number;
  minWidth?: number;
  onClick?: (event: SyntheticEvent) => void;
}

const Button = React.forwardRef<HTMLButtonElement, PropTypes>(
    (
        {
            href,
            target = '_self',
            form,
            disabled,
            minWidth = 'unset',
            secondary,
            actionButton,
            yellowActionButton,
            transparent,
            htmlType,
            styles,
            className,
            iconLeft,
            iconRight,
            iconMargin = 5,
            children,
            onClick,
            ...rest
        },
        ref
    ) => {
        const buttonClasses = useMemo(
            () =>
                cx(
                    'btn-text',
                    sty.button,
                    {
                        [sty.icon]: iconLeft || iconRight,
                        [sty.actionButton]: actionButton,
                        [sty.yellowActionButton]: yellowActionButton,
                        [sty.transparent]: transparent,
                        [sty.secondary]: secondary,
                    },
                    className
                ),
            [
                iconLeft,
                iconRight,
                actionButton,
                className,
                yellowActionButton,
                transparent,
                secondary
            ]
        );

        const buttonStyles = useMemo(
            () => ({
                ...styles,
                minWidth: styles?.minWidth,
            }),
            [styles]
        );

        const renderButton = (
            <div className={sty.buttonWrapper}>
                <button
                    {...rest}
                    ref={ref}
                    form={form}
                    style={buttonStyles}
                    disabled={disabled}
                    className={cx(buttonClasses)}
                    onClick={onClick}
                >
                    {Boolean(iconLeft) && (
                        <span
                            className={sty.iconWrapper}
                            style={{ marginRight: `${iconMargin}px` }}
                        >
                            {iconLeft}
                        </span>
                    )}
                    {children}
                    {Boolean(iconRight) && (
                        <span
                            className={sty.iconWrapper}
                            style={{ marginLeft: `${iconMargin}px` }}
                        >
                            {iconRight}
                        </span>
                    )}
                </button>
                <div className={sty.jaw}/>
            </div>
        );

        if (href) {
            return (
                <Link to={href} target={target}>
                    {renderButton}
                </Link>
            );
        }

        return renderButton;
    }
);

Button.defaultProps = {
    disabled: false,
    className: '',
};
Button.displayName = 'Button';
export default Button;
