import React from 'react';
import styles from './RequiredLabel.module.scss';
interface IRequiredLabelProps {
    children: React.ReactNode;
    isRequired:boolean;
}
const RequiredLabel: React.FC<IRequiredLabelProps> = ({children,isRequired=false}) => {
    return (
        isRequired ?
            <span>{children}</span>
            :
            <span>{children}<span className={styles.redStar}> *</span></span>
    );
};

export default RequiredLabel;