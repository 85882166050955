import React from 'react';

const AngleDown = ({fill}:{fill?: string}) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.9942 15.709L5.64062 9.35547H7.05484L11.9942 14.2948L16.9335 9.35547H18.3478L11.9942 15.709Z" fill={fill || '#355479'}/>
        </svg>
    );
};

export default AngleDown;