import React, { useEffect, useState } from 'react';
import styles from './NavigationMenu.module.scss';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import Paths from '../../../../consts/Paths';
import { useAppDispatch } from '../../../../redux/hooks';
import { lockBody, unlockBody } from '../../../../redux/slices/global';
import StartVoiceBtn from '../StartVoiceBtn';
import useWindowSize from '../../../../hooks/useWindowSize';
import { xl } from '../../../../consts/Breakpoints';

const MAIN_PAGES = [
    {
        url: Paths.aboutPage,
        label: 'Contact Us',
    },
];

interface INavigationProps {
    isDark?: boolean;
}

const Navigation = ({ isDark }: INavigationProps): JSX.Element => {

    const [isMenuPopupActive, setIsMenuPopupActive] = useState(false);
    const { width } = useWindowSize();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (width > xl) {
            setIsMenuPopupActive(false);
            dispatch(unlockBody());
        }
    }, [dispatch, width]);

    const classes = classNames(styles.menuWrapper, {
        [styles.active]: isMenuPopupActive,
        [styles.dark]: isDark
    });

    function openPopup() {
        setIsMenuPopupActive(true);
        dispatch(lockBody());
    }

    function closePopup() {
        setIsMenuPopupActive(false);
        dispatch(unlockBody());
    }
    const scrollToElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <>
            <div className={classes}>
                <div className={styles.closeBtn}>
                    <span
                        onClick={closePopup}
                    >Close</span>
                </div>
                <ul>
                    {MAIN_PAGES.map(({ url, label }) => (
                        <li className={classNames(styles.link, { [styles.dark]: isDark })} key={url}>
                            <NavLink onClick={() => {
                                dispatch(unlockBody());
                                scrollToElement('contact-us');
                            }}
                            to={url}
                            className={({ isActive }) => isActive ? styles.activeClass : ''}
                            end
                            >
                                <span>
                                    {label}
                                </span>
                            </NavLink>
                        </li>
                    ))}

                </ul>
            </div>
            <StartVoiceBtn titleOption='starts' className={styles.mobileStartBtn} />
        </>

    );
};

export default Navigation;