import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Team, IRejectValue } from 'models/team';
import TeamApi from '../../../api/teamApi';
import errorHandler from '../../../utils/errorHandler';

const teamApi = new TeamApi();

interface TeamState {
  team: Team[];
}

const initialState: TeamState = {
    team: [],
};

export const getTeam = createAsyncThunk<Team[], Record<string, any> , IRejectValue>('Team/getList',
    async (data, { rejectWithValue }) => {
        try {
            const response = await teamApi.getTeamList(data);
            return response.data.teams;
        } catch (e) {
            return rejectWithValue(errorHandler('Get Team Member List', e));
        }
    }
);

export const slice = createSlice({
    name: 'team',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getTeam.fulfilled, (state, { payload }) => {
            state.team = payload;
        });
    },
});

export default slice.reducer;
