import React from 'react';

const AngleArrow: React.FC = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            width={7}
            height={14}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M0 6.998 6.354.645v1.414l-4.94 4.939 4.94 4.94v1.414L0 6.998Z"
                fill="#355479"
            />
        </svg>
    );
};

export default AngleArrow;
