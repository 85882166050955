import React from 'react';
import {IIconProps} from '../../../../types';
import {black, white as whiteColor} from '../../../../consts/Colors';

const ProjectLogo = ({white}:IIconProps) => {
    return (
        <svg width="68" height="60" viewBox="0 0 68 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4989_6296)">
                <path d="M30.6069 0H0.586639C0.262648 0 0 0.261651 0 0.584413V6.10871C0 6.43145 0.262647 6.6931 0.586639 6.6931H30.6069C30.9309 6.6931 31.1935 6.43145 31.1935 6.10871V0.584413C31.1935 0.261651 30.9309 0 30.6069 0Z"
                    fill={white ? whiteColor : black}/>
                <path d="M67.3193 13.1468H0.586639C0.262648 13.1468 0 13.4084 0 13.7312V19.0164C0 19.3392 0.262649 19.6008 0.586642 19.6008H67.3193C67.6432 19.6008 67.9061 19.3392 67.9061 19.0164V13.7312C67.9061 13.4084 67.6432 13.1468 67.3193 13.1468Z"
                    fill={white ? whiteColor : black}/>
                <path d="M61.5604 26.5329H0.586639C0.262648 26.5329 0 26.7946 0 27.1173V32.4026C0 32.7252 0.262651 32.9872 0.586643 32.9872H61.5604C61.8845 32.9872 62.1471 32.7252 62.1471 32.4026V27.1173C62.1471 26.7946 61.8845 26.5329 61.5604 26.5329Z"
                    fill={white ? whiteColor : black}/>
                <path d="M38.5252 39.442H0.586639C0.262648 39.442 0 39.7039 0 40.0265V45.5507C0 45.8736 0.262648 46.1352 0.586639 46.1352H38.5252C38.8494 46.1352 39.112 45.8736 39.112 45.5507V40.0265C39.112 39.7039 38.8494 39.442 38.5252 39.442Z"
                    fill={white ? whiteColor : black}/>
                <path d="M33.4864 53.3069H0.586639C0.262648 53.3069 0 53.5685 0 53.8914V59.4156C0 59.7382 0.262649 60.0001 0.586642 60.0001H33.4864C33.8102 60.0001 34.0729 59.7382 34.0729 59.4156V53.8914C34.0729 53.5685 33.8102 53.3069 33.4864 53.3069Z"
                    fill={white ? whiteColor : black}/>
            </g>
            <defs>
                <clipPath id="clip0_4989_6296">
                    <rect width="68" height="60" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default ProjectLogo;
