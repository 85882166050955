import React from 'react';
import styles from './loading.module.scss';
import {useLottie} from 'lottie-react';
import LoaderBlue from '../../assets/animations/loaderBlue.json';
import LoaderGreen from '../../assets/animations/loaderGreen.json';

interface ILoadingProps {
    green?: boolean;
}
const Loading = ({green}:ILoadingProps):JSX.Element => {

    const { View } = useLottie({
        animationData: green ? LoaderGreen : LoaderBlue,
        loop: true,
    });

    return (
        <div className={styles.loadingWrapper}>
            {View}
        </div>
    );
};

export default Loading;