// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading_loadingWrapper__Tonac {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(to right, #c8d9cf, #f4f7f6);
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Loading/loading.module.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,iBAAA;EACA,WAAA;EACA,uDAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AADF","sourcesContent":["@import \"./src/styles/variables\";\n\n.loadingWrapper{\n  height: 100%;\n  min-height: 100vh;\n  width: 100%;\n  background: linear-gradient(to right, $waterGreen, $white);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingWrapper": `loading_loadingWrapper__Tonac`
};
export default ___CSS_LOADER_EXPORT___;
