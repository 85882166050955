import React from 'react';

const Close = ({fill}:{fill?: string | false}):JSX.Element => {
    return (
        <svg
            width={15}
            height={13}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="m8.05 6.5 6.2 6.5h-1.6l-5.4-5.7-5.4 5.7H.25l6.2-6.5L.25 0h1.6l5.4 5.7 5.4-5.7h1.6l-6.2 6.5Z"
                fill={fill || '#365479'}
            />
        </svg>
    );
};

export default Close;
