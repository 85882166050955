import React from 'react';
import cx from 'classnames';
import styles from './SpeechBubble.module.scss';
const speech_bubble_triangle = `${process.env.REACT_APP_CLOUDFRONT_URI}/figures/speech-bubble-triangle.svg`;

interface ISpeechBubbleProps {
    text: string;
    decideClass?: 1 | 2 | 3 | 4;
    left?:boolean;
}
const SpeechBubble: React.FC<ISpeechBubbleProps> = ({ text, decideClass = 1, left=false}) => {

    const classMapping = {
        1: styles.surveyArea,
        2: styles.surveyAreaExtended,
        3: styles.optionalMessage,
        4: styles.matrixQuestion,
    };
    const className = classMapping[decideClass];
    return (
        <div className={cx(className)}>
            <span>
                <img src={speech_bubble_triangle} alt="Speech bubble Image" />
            </span>
            <div className={left?cx(styles.speechBubbleleft, 'description-sm_survey'):cx(styles.speechBubble, 'description-sm_survey')}>
                {text}
            </div>
        </div>
    );
};

export default SpeechBubble;