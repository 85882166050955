import React, { useEffect, useState } from 'react';
import Container from 'components/Container';
import Button from 'components/Buttons';
import styles from './Footer.module.scss';
import FooterBottomLine from './FooterBottomLine';
import InputField from '../InputField';
import classNames from 'classnames';
import cx from 'classnames';
import {useAppDispatch, useAppSelector} from 'redux/hooks';
import { emailRegExp } from 'consts/RegExp';
import { createSubscription } from 'redux/slices/subscription';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faXTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';

const background = `${process.env.REACT_APP_CLOUDFRONT_URI}/backgrounds/background-3.png`;

const Footer: React.FC = () => {
    const content = useAppSelector(state => state.global.content?.landingPage);
    const contentSocialMedia = useAppSelector(state => state.global.content?.SocialMedia);
    const dispatch = useAppDispatch();
    const [isError, setIsError] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>('');
    const [errMsg, setErrMsg] = useState<string>('');

    useEffect(() => {
        if(isSuccess){
            const timer = setTimeout(() => {
                setIsSuccess(false);
                setInputValue('');
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [isSuccess]);

    const onSubmitHandler = () => {
        setErrMsg('');
        setIsSuccess(false);
        if(emailRegExp.test(inputValue)){
            setIsError(false);
            dispatch(
                createSubscription({email: inputValue}))
                .unwrap()
                .then((res) => {
                    if(!res.data){
                        setErrMsg(res.message);
                        setIsError(true);
                    } else{
                        setIsSuccess(true);
                    }
                })
                .catch((err) => {
                    console.log('Error::', err);
                });
            return;
        }
        setIsError(true);
    };

    return (
        <>
            <section className={styles.wrapperFooter}>
                <Container>
                    <div className={cx(styles.wrapperContent, 'description-sm')}>
                        <div className={styles.leftItems}>
                            <div className={styles.column}>
                                <span className="subtitle mb-30">More information</span>
                                <a href="https://research.kent.ac.uk/psypol/" target="_blank">Technical Documentation for Researchers</a>
                                <ul className={styles.iconBtn}>
                                    <li className={styles.listicon}>
                                        <a href={contentSocialMedia?.Facebook} target="_blank" className={styles.icon}><FontAwesomeIcon icon={faFacebookF} /></a>
                                    </li>
                                    <li className={styles.listicon}>
                                        <a href={contentSocialMedia?.Twiter} target="_blank" className={styles.icon}><FontAwesomeIcon icon={faXTwitter} /></a>
                                    </li>
                                    <li className={styles.listicon}>
                                        <a href={contentSocialMedia?.Youtube} target="_blank" className={styles.icon}><FontAwesomeIcon icon={faYoutube} /></a>
                                    </li>
                                </ul>
                            </div>
                            <div className={styles.column}>
                                <span className="subtitle mb-30">{content?.ContactUsTitle}</span>
                                <a href={`tel:${content?.ContactUsPhone}`}>+{content?.ContactUsPhone}</a>
                                <a className='mb-10 underlineContent' href={`mailto:${content?.ContactUsEmail}`}>{content?.ContactUsEmail}</a>
                            </div>
                        </div>
                        <div className={styles.rightItems}>
                            <h6 className="subtitle mb-40">{content?.NewsletterTitle}</h6>
                            <div className={classNames(styles.wrapperInput, 'mb-30')}>
                                <InputField
                                    onChange={(e) => setInputValue(e.target.value)}
                                    value={inputValue}
                                    placeholder={content?.SubscribeInputPlaceholder}
                                    light
                                />
                                <Button onClick={onSubmitHandler}>{content?.SubscribeButtonText}</Button>
                            </div>
                            {isError && <div className={styles.errorBox}>
                                <span className='errorText'>{errMsg ? errMsg : 'Please enter a valid email address.'}</span>
                            </div>}
                            {
                                content?.NewsletterDescription && (
                                    <p className="subtitle-sm mr-0" dangerouslySetInnerHTML={{ __html: content?.NewsletterDescription }} />
                                )
                            }
                        </div>
                    </div>
                </Container>
            </section>
            <FooterBottomLine background={`center / cover no-repeat url(${background})`}/>
        </>
    );
};

export default Footer;
