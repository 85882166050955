export default Object.freeze({
    aboutPage: '/',
    contactInfo: '/contact-info',
    privacyPolicy: '/privacy-policy',
    cookies: '/cookies',
    onboardingPage: '/onboarding-questions',
    surveys: '/surveys',
    consentForm: '/consent-form',
    resultPage: '/result',
    survey: '/survey',
    researchPage: '/research',
    news: '/news',
    notFound: '/404',
    uniqueUrl: '/code/:url',
});