import {useEffect, useState} from 'react';
import {Elements, SessionQuestions} from '../models/survey';

export const getFromLocalStorage = (key: string) => {
    // tslint:disable-next-line:strict-type-predicates
    if (typeof localStorage === 'undefined' || localStorage === null) {
        return null;
    }

    const data = localStorage.getItem(key);

    if (data !== null) {
        try {
            return JSON.parse(data);
        } catch (error) {
            // tslint:disable-next-line: no-console
            return data;
        }
    }

    return null;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const saveInLocalStorage = (key: string, value: any) => {
    // tslint:disable-next-line:strict-type-predicates
    if (typeof localStorage === 'undefined' || localStorage === null) {
        return null;
    }

    if (typeof value === 'string') {
        localStorage.setItem(key, value);
    }

    localStorage.setItem(key, JSON.stringify(value));
};



export const removeFromLocalStorage = (key: string) => {
    // tslint:disable-next-line:strict-type-predicates
    if (typeof localStorage === 'undefined' || localStorage === null) {
        return null;
    }

    localStorage.removeItem(key);
};

export function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
}


export function filterObject(obj: Record<string, { [key: string]: string}>, callback: (key: string, val: unknown ) => boolean) {
    return Object.fromEntries(Object.entries(obj)
        .filter(([key, val]) => callback(key, val)));
}

export function useScrollDirection() {
    const [scrollDirection, setScrollDirection] = useState<'down' | 'up'>();

    useEffect(() => {
        let lastScrollY = window.scrollY;

        const updateScrollDirection = () => {
            const scrollY = window.scrollY;
            const direction = scrollY > lastScrollY ? 'down' : 'up';
            if (direction !== scrollDirection && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
                setScrollDirection(direction);
            }
            lastScrollY = scrollY > 0 ? scrollY : 0;
        };
        window.addEventListener('scroll', updateScrollDirection);
        return () => {
            window.removeEventListener('scroll', updateScrollDirection);
        };
    }, [scrollDirection]);

    return scrollDirection;
}

export function compareArrayOfQuestions(allQuestions:Elements[], updatedQuestions:SessionQuestions[]):boolean {
    return updatedQuestions.every((question) => {
        return allQuestions.some(element => element.questionId === question.questionId);
    });
}

export const randomizeZeroOrOne = () => (Math.random()>=0.5) ? 1 : 0;
export function getRandomInt(max: number) {
    return Math.floor(Math.random() * max);
}

export const getFromSessionStorage = (key: string) => {
    // tslint:disable-next-line:strict-type-predicates
    if (typeof sessionStorage === 'undefined' || sessionStorage === null) {
        return null;
    }

    const data = sessionStorage.getItem(key);

    if (data !== null) {
        try {
            return JSON.parse(data);
        } catch (error) {
            return data;
        }
    }

    return null;
};

export const saveInSessionStorage = (key: string, value: any) => {
    // tslint:disable-next-line:strict-type-predicates
    if (typeof sessionStorage === 'undefined' || sessionStorage === null) {
        return null;
    }

    if (typeof value === 'string') {
        sessionStorage.setItem(key, value);
    }

    sessionStorage.setItem(key, JSON.stringify(value));
};
