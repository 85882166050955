import React,{useEffect, useState} from 'react';
import {Carousel as RowCarousel} from 'react-responsive-carousel';
import styles from './Carousel.module.scss';

interface ICarousel {
    children: JSX.Element[];
    className?: string;
    dark?: boolean;
    infiniteLoop?: boolean;
    handleChange: () => void;
}
const Carousel: React.FC<ICarousel> = ({children, className, dark, infiniteLoop= true, handleChange}) => {
    const [key, setKey] = useState(Math.random());

    useEffect(() => {
        setTimeout(() => {
            setKey(Math.random());
        }, 1000);
    }, []);

    return (
        <div className={styles.carousel}>
            <RowCarousel
                key={key}
                className={className}
                infiniteLoop={true}
                autoPlay={true}
                interval={3000}
                showStatus={false}
                showThumbs={false}
                showArrows={false}
                showIndicators={false}
                onChange={handleChange}
            >
                {children}
            </RowCarousel>
        </div>

    );
};

export default Carousel;