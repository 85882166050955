
export enum EContentKeys{
    RESULT_PAGE_KEY = 'Result',
    CONTACT_PAGE_KEY = 'Contact',
    CONTACT_CODE_PAGE_KEY = 'ContactCode',
    FOOTER_PAGE_KEY = 'Footer',
    SURVEY_PAGE_KEY = 'Block',
    LEAVE_PAGE_KEY = 'Leave',
    CONSENT_PAGE_KEY = 'Intro',
    COOKIES_PAGE_KEY = 'Cookies',
    COOKIES_POPUP_PAGE_KEY = 'CookiesPopup',
    IMAGES_PAGE_KEY = 'Image',
    PRIVACY_POLICY_PAGE_KEY = 'PrivacyPolicy',
    LANDING_PAGE_KEY = 'landingPage',
    SOCIAL_PAGE_KEY = 'SocialMedia',
    CONSENT_FORM_PAGE_KEY = 'ConsentForm'
}

