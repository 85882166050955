import React from 'react';
import classes from 'classnames';
import {NavLink} from 'react-router-dom';
import styles from './Header.module.scss';
import Navigation from './Components/NavigationMenu';
import {useScrollDirection} from '../../utils/common';
import StartVoiceBtn from './Components/StartVoiceBtn';
import KentLogo from '../../assets/icons/logo/KentLogo/KentLogo';
import KentLogoSm from '../../assets/icons/logo/KentLogo/KentLogoSm';
import useWindowSize from '../../hooks/useWindowSize';
import {md} from '../../consts/Breakpoints';
import ProjectLogo from '../../assets/icons/logo/ProjectLogo/ProjectLogo';
import ProjectLogoSm from '../../assets/icons/logo/ProjectLogo/ProjectLogoSm';

interface IHeaderProps {
    light?: boolean;
}

const Header: React.FC<IHeaderProps> = ({light}) => {
    const scrollDirection = useScrollDirection();
    const {width} = useWindowSize();
    return (
        <header
            className={classes(styles.header, {
                [styles.background]: !light,
                [styles.light]: light,
                [styles.down]: scrollDirection === 'down',
            })}
        >
            <nav>
                <div className={styles.logo}>
                    <div className={styles.logoWrapper} >
                        <NavLink to="/">
                            {width > md ? <ProjectLogo white={!light}/> : <ProjectLogoSm white={!light}/> }
                        </NavLink>
                    </div>
                    <div className={styles.kentLogoWrapper}>
                        {width > md ? <KentLogo white={!light}/> : <KentLogoSm white={!light}/> }
                        <div className={styles.kentLogoDescription}>
                            Funded by the European <br/>Research Council
                        </div>
                    </div>
                </div>
                <Navigation isDark={light}/>
                <StartVoiceBtn className={styles.desktopStartSurveyBtn}/>
            </nav>
        </header>
    );
};

export default Header;
