import React, { useState } from 'react';
import cx from 'classnames';
import styles from './NextButton.module.scss';
import MessageBlock from '../../Message';
import SpeechBubble from 'components/SpeechBubble';
import { EContentKeys } from '../../../consts/ContentKeys';
import { selectContent } from '../../../redux/slices/contactInfo/selectors';
import { useAppSelector } from '../../../redux/hooks';
import { submitSessionResult } from 'redux/slices/survey';
import { IError } from 'models/survey';
import { resultSession } from 'api/surveyApi/surveyApi.type';
import { useAppDispatch } from 'redux/hooks';
import { getFromLocalStorage } from 'utils/common';

interface PropTypes {
    className?: string;
    onClick?: () => void;
    children: React.ReactNode;
    alertConfig?: {
        component: React.ReactNode;
        visible: boolean;
    };
    isLoading?: boolean;
    disabled?: boolean;
    hideLine?: boolean;
    optional?: boolean;
    isEmailSub?: boolean;
    isEmailBoxChecked?: boolean;
    onEmailBoxVerify?: () => Promise<boolean>;
    onHoverButton?: () => void;
    buttonRef?: React.RefObject<HTMLDivElement>;
    tabIndex?:number;
    isOptional?:boolean;
    onHover?: () => void;
    isConsentForm?:boolean;
    isVoluntary?:boolean;
    isParticipate?:boolean;
    isSurvey?: boolean;
    dataTest?: resultSession[];
    emptyOptionData?: () => void;
}

const NextButton: React.FC<PropTypes> = ({
    onClick = () => null,
    children,
    className,
    isLoading,
    disabled,
    hideLine,
    alertConfig,
    isEmailSub = false,
    isEmailBoxChecked = false,
    onEmailBoxVerify = () => true,
    onHoverButton = () => null,
    buttonRef,
    isOptional=false,
    tabIndex=0,
    onHover = () => null,
    isConsentForm= false,
    isParticipate= false,
    isVoluntary=false,
    isSurvey = false,
    emptyOptionData = () => null,
    dataTest
}) => {

    const adaptiveClasses = {
        [styles.buttonLoading]: isLoading,
        [styles.disabled]: disabled,
        [styles.hideLine]: hideLine
    };
    const [showBubble, setShowBubble] = useState(false);
    const content = useAppSelector(selectContent)[EContentKeys.CONSENT_PAGE_KEY];
    const dispatch = useAppDispatch();
    const onMouse = () => {
        setShowBubble(true);
        onHoverButton();
        onHover();
    };
    const onMouseLeave = () => {
        setShowBubble(false);
    };
    const onClickSubmit = async () => {
        if (isSurvey) {
            if(dataTest && dataTest.length>0){
                const qualtricsId = getFromLocalStorage('qualtricsId');
                const publicCode = getFromLocalStorage('publicCode');
                dispatch(submitSessionResult({ surveyId:qualtricsId, publicCode:publicCode, data:dataTest }))
                    .then((res) => {
                        emptyOptionData();
                    })
                    .catch((e: IError) => {
                        console.log('error',e);
                    });
            }
        }
        onClick();
        // if (isEmailBoxChecked) {
        //     const res = await onEmailBoxVerify();
        //     if (res) {
        //         onClick();
        //     }
        // } else {
        //     onClick();
        // }
    };
    const onKeyDownSubmit = async (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            onClick();
            // if (isEmailBoxChecked) {
            //     const res = await onEmailBoxVerify();
            //     if (res) {
            //         onClick();
            //     }
            // } else {
            //     onClick();
            // }
        }
    };
    return (
        <div>
            <div className={styles.positionBlock}>
                <div className={cx(styles.wrapperButton, className, adaptiveClasses)} ref={buttonRef} tabIndex={-1} onKeyDown={onKeyDownSubmit}>
                    {alertConfig && <div className={cx(styles.alertWrapper, { [styles.visible]: alertConfig.visible })}>
                        <MessageBlock>
                            <div className={styles.reverseBlock}>
                                {alertConfig.component}
                            </div>
                        </MessageBlock>
                    </div>}
                    <span
                        onMouseEnter={() => {
                            onMouse();
                        }}
                        onMouseLeave={() => {
                            onMouseLeave();
                        }}
                    >
                        <button type="submit" className={styles.nextButton} onClick={!disabled ? onClickSubmit : () => (onClickSubmit)} >
                            {children}
                        </button>
                    </span>
                </div>
                {(!isOptional) && showBubble && disabled && isConsentForm && (
                    <SpeechBubble text={(isEmailSub && isParticipate && isVoluntary) ? 'Please enter your email address to proceed' : 'Please check the required checkboxes to proceed'} decideClass={3} />
                )}
            </div>
        </div>
    );
};

export default NextButton;
