import {IGetContentList} from '../api/contentApi/contentApi.type';
import {IContentDTO} from '../redux/slices/global/types';
import {EContentKeys} from '../consts/ContentKeys';

export const CreateContentDTOFromContentListResponse = (response: IGetContentList): IContentDTO => {
    return response.data.reduce((previousValue, {key, content}) => {
        const keys = key.split(':');
        const pageKey = keys[0] as EContentKeys;
        const contentKey =
            (
                pageKey === EContentKeys.PRIVACY_POLICY_PAGE_KEY ||
                pageKey === EContentKeys.COOKIES_PAGE_KEY ||
                pageKey === EContentKeys.FOOTER_PAGE_KEY ||
                pageKey === EContentKeys.CONSENT_FORM_PAGE_KEY
            ) ? 'Body' : keys[1];

        if(pageKey in previousValue){
            previousValue[pageKey][contentKey] = content;
            return previousValue;
        }

        return {
            ...previousValue,
            [pageKey]: {
                [contentKey]: content
            }
        };
    }, {} as IContentDTO);
};