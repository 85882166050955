import React, { useState } from 'react';
import cx from 'classnames';
import { useAppSelector } from 'redux/hooks';
import sty from './Collapse.module.scss';
import { IFaqItem } from '../../pages/AboutPage/AboutPageType';
import { Button } from 'components';
interface PropTypes {
    data: IFaqItem[];
    withButton?: boolean;
    className?: string;
}

const Collapse: React.FC<PropTypes> = ({ data, withButton = false }) => {
    const [showMore, setShowMore] = useState(false);

    const element = (item: IFaqItem, index: number) => (
        <li key={item.id} className={sty.item}>
            <input type="checkbox" defaultChecked={index === 0 ? false : true} className={sty.switch} />
            <i className={sty.icon}></i>
            <span className={cx('subtitle', sty.title)}>{item.question}</span>
            <p className={cx('description-sm', sty.description)}>{item.answer}</p>
        </li>
    );

    const list = React.useMemo(
        () =>
            data.map((item, index) => {
                if (withButton) {
                    if (index < data.length - 5) {
                        return element(item, index);
                    }
                    if (index > data.length - 6 && showMore) {
                        return element(item, index);
                    }
                }
                return element(item,index);
            }),
        [data, showMore, withButton]
    );

    return (
        <ul className={sty.collapse}>
            {list}
            {withButton && (
                <div className={sty.openBtnWrapper}>
                </div>
            )}
        </ul>
    );
};

interface BtnTexts {
    faqBtnTxt: string,
}
const Buttons = ({ faqBtnTxt }: BtnTexts) => (
    <>
        <Button href='#'>{faqBtnTxt}</Button>
    </>
);


export default Collapse;
