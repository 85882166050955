// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Container_container__MM274 {
  margin: 0 auto;
  width: 100%;
  max-width: 1920px;
  padding: 0 150px;
}
@media (max-width: 1200px) {
  .Container_container__MM274 {
    padding: 0 75px;
  }
}
@media (max-width: 992px) {
  .Container_container__MM274 {
    padding: 0 25px;
  }
}
@media (max-width: 768px) {
  .Container_container__MM274 {
    padding: 0 16px;
  }
}
.Container_container__MM274.Container_survey__Xvd-\\+ {
  padding: 0 40px;
}
@media (max-width: 768px) {
  .Container_container__MM274.Container_survey__Xvd-\\+ {
    padding: 0 16px;
  }
}
.Container_container__MM274.Container_xxl__dXfo4 {
  max-width: 1440px;
}
.Container_container__MM274.Container_xl__9vzIs {
  max-width: 1200px;
}
.Container_container__MM274.Container_large__Npek1 {
  max-width: 992px;
}
.Container_container__MM274.Container_middle__5s7QP {
  max-width: 768px;
}
.Container_container__MM274.Container_small__1xSc5 {
  max-width: 576px;
}`, "",{"version":3,"sources":["webpack://./src/components/Container/Container.module.scss"],"names":[],"mappings":"AAEA;EACE,cAAA;EACA,WAAA;EACA,iBAAA;EACA,gBAAA;AADF;AAGE;EANF;IAOI,eAAA;EAAF;AACF;AAEE;EAVF;IAWI,eAAA;EACF;AACF;AACE;EAdF;IAeI,eAAA;EAEF;AACF;AAAE;EACE,eAAA;AAEJ;AAAI;EAHF;IAII,eAAA;EAGJ;AACF;AAAE;EACC,iBAAA;AAEH;AACE;EACE,iBAAA;AACJ;AAEE;EACE,gBAAA;AAAJ;AAGE;EACE,gBAAA;AADJ;AAIE;EACE,gBAAA;AAFJ","sourcesContent":["@import \"../../styles/variables\";\n\n.container {\n  margin: 0 auto;\n  width: 100%;\n  max-width: 1920px;\n  padding: 0 150px;\n\n  @media (max-width: map-get($grid-breakpoints, xl)) {\n    padding: 0 75px;\n  }\n\n  @media (max-width: map-get($grid-breakpoints, lg)) {\n    padding: 0 25px;\n  }\n\n  @media (max-width: map-get($grid-breakpoints, md)) {\n    padding: 0 16px;\n  }\n\n  &.survey{\n    padding: 0 40px;\n\n    @media (max-width: map-get($grid-breakpoints, md)) {\n      padding: 0 16px;\n    }\n  }\n\n  &.xxl {\n   max-width:map-get($grid-breakpoints, xxl)\n  }\n\n  &.xl {\n    max-width: map-get($grid-breakpoints, xl);\n  }\n\n  &.large {\n    max-width: map-get($grid-breakpoints, lg);\n  }\n\n  &.middle {\n    max-width: map-get($grid-breakpoints, md);\n  }\n\n  &.small {\n    max-width: map-get($grid-breakpoints, sm);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Container_container__MM274`,
	"survey": `Container_survey__Xvd-+`,
	"xxl": `Container_xxl__dXfo4`,
	"xl": `Container_xl__9vzIs`,
	"large": `Container_large__Npek1`,
	"middle": `Container_middle__5s7QP`,
	"small": `Container_small__1xSc5`
};
export default ___CSS_LOADER_EXPORT___;
