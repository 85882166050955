import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IContentDTO, IInitialGlobalState} from './types';
import {IRejectValue} from '../../../models/survey';
import errorHandler from '../../../utils/errorHandler';
import ContentApi from '../../../api/contentApi';
import {CreateContentDTOFromContentListResponse} from '../../../dto/ContentDTOs';
import {saveInSessionStorage} from '../../../utils/common';

const contentApi = new ContentApi();

const initialState: IInitialGlobalState = {
    lockedBody: false,
    isLoading: false,
    error: false,
    content: null,
};

export const getContentList = createAsyncThunk<IContentDTO, void, IRejectValue>(
    'global/getContentList',
    async (arg, {rejectWithValue}) => {
        try {
            return CreateContentDTOFromContentListResponse(await contentApi.getContentList());
        } catch (e) {
            return rejectWithValue(errorHandler('Get Survey Id', e));
        }
    }
);

const globalReducer = createSlice({
    initialState,
    name: 'global',
    reducers: {
        lockBody: state => {
            state.lockedBody = true;
        },
        unlockBody: state => {
            state.lockedBody = false;
        },
        setContent: (state, {payload}:PayloadAction<IContentDTO>) => {
            state.content = payload;
        }
    },
    extraReducers: builder =>
        builder.addCase(getContentList.pending, (state) => {
            state.isLoading = true;
        }).addCase(getContentList.fulfilled, (state, action) => {
            state.isLoading = false;
            state.content = action.payload;
            saveInSessionStorage('content', action.payload);
        }).addCase(getContentList.rejected, (state) => {
            state.isLoading = false;
            state.error = true;
        })
});

export const {
    lockBody,
    unlockBody,
    setContent
} = globalReducer.actions;

export default globalReducer.reducer;