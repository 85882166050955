import React from 'react';
import styles from './message.module.scss';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';

interface IMessageBlockProps {
    children: React.ReactNode;
    className?: string;
    rightArray?: boolean;
    fourth?: boolean;
    onLeaveButton?: () => void;

}

const MessageBlock: React.FC<IMessageBlockProps> = ({ children, className, rightArray, fourth = false, onLeaveButton = () => null, }) => {
    const onMouseLeave = () => {
        onLeaveButton();
    };
    const classes = cx(styles.messageWrapper, className, 'subtitle', {
        [styles.right]: rightArray
    }, fourth ? styles.fourthBox : styles.thirdBox);

    return (
        <div className={classes}>
            <div className={styles.mainClasses}>
                <div className={styles.message}>{children}</div>
                <div className={styles.substrate}></div>
                <div className={styles.icon} onClick={
                    () => {
                        onMouseLeave();
                    }}>
                    <FontAwesomeIcon icon={faCircleXmark} />
                </div>
            </div>
        </div>
    );
};

export default MessageBlock;