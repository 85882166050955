import React, {useEffect} from 'react';
import AppRoutes from 'AppRoutes';
import 'app/styles/index.scss';
import {useAppDispatch, useAppSelector} from '../redux/hooks';
import {getContentList, setContent} from '../redux/slices/global';
import {getFromSessionStorage} from '../utils/common';
import {localStorageKeys} from '../consts/LocalStorageKeys';
import {Loading} from '../components';

const App: React.FC = () => {
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(state => state.global.isLoading);
    const content = useAppSelector(state => state.global.content);
    const localContent = getFromSessionStorage(localStorageKeys.content);

    useEffect(() => {
        localContent ?
            dispatch(setContent(localContent))
            :
            dispatch(getContentList());
    }, []);

    if(isLoading || !content){
        return <Loading/>;
    }

    return (
        <div className="appWrapper">
            <AppRoutes />
        </div>
    );
};

export default App;
