// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Pupup_wrapperModal__puk1f {
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  padding: 40px;
  display: none;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background: rgba(22, 29, 27, 0.3);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  z-index: 100;
}
.Pupup_wrapperModal__puk1f.Pupup_active__TbEr5 {
  display: flex;
}
.Pupup_wrapperModal__puk1f.Pupup_active__TbEr5 .Pupup_childrenAnimationBox__mqerS {
  animation: Pupup_anvil__HwG7M 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}
.Pupup_wrapperModal__puk1f .Pupup_childrenAnimationBox__mqerS {
  width: 100%;
  max-width: 900px;
}

@keyframes Pupup_anvil__HwG7M {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Popup/Pupup.module.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,WAAA;EACA,aAAA;EACA,gBAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,MAAA;EACA,OAAA;EACA,iCAAA;EACA,kCAAA;UAAA,0BAAA;EACA,YAAA;AADF;AAGE;EACE,aAAA;AADJ;AAEI;EACE,8EAAA;AAAN;AAIE;EACE,WAAA;EACA,gBAAA;AAFJ;;AAOA;EACE;IACE,mCAAA;IACA,UAAA;IACA,wCAAA;EAJF;EAMA;IACE,uCAAA;IACA,UAAA;IACA,wCAAA;EAJF;EAMA;IACE,mCAAA;IACA,UAAA;IACA,4CAAA;EAJF;AACF","sourcesContent":["@import \"./src/styles/variables\";\r\n\r\n.wrapperModal {\r\n  position: fixed;\r\n  width: 100%;\r\n  height: 100vh;\r\n  overflow: hidden;\r\n  padding: 40px;\r\n  display: none;\r\n  justify-content: center;\r\n  align-items: center;\r\n  top: 0;\r\n  left: 0;\r\n  background: rgba(22, 29, 27, 0.3);\r\n  backdrop-filter: blur(5px);\r\n  z-index: 100;\r\n\r\n  &.active{\r\n    display: flex;\r\n    .childrenAnimationBox{\r\n      animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;\r\n    }\r\n  }\r\n\r\n  .childrenAnimationBox{\r\n    width: 100%;\r\n    max-width: 900px;\r\n  }\r\n}\r\n\r\n\r\n@keyframes anvil {\r\n  0% {\r\n    transform: scale(1) translateY(0px);\r\n    opacity: 0;\r\n    box-shadow: 0 0 0 rgba(241, 241, 241, 0);\r\n  }\r\n  1% {\r\n    transform: scale(0.96) translateY(10px);\r\n    opacity: 0;\r\n    box-shadow: 0 0 0 rgba(241, 241, 241, 0);\r\n  }\r\n  100% {\r\n    transform: scale(1) translateY(0px);\r\n    opacity: 1;\r\n    box-shadow: 0 0 500px rgba(241, 241, 241, 0);\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperModal": `Pupup_wrapperModal__puk1f`,
	"active": `Pupup_active__TbEr5`,
	"childrenAnimationBox": `Pupup_childrenAnimationBox__mqerS`,
	"anvil": `Pupup_anvil__HwG7M`
};
export default ___CSS_LOADER_EXPORT___;
