import React from 'react';
import classNames from 'classnames';
import styles from './ShowMoreBtn.module.scss';
import AngleDown from '../../../assets/icons/AngleDown';

interface IShowMoreBtnProps{
    isActive: boolean;
    onClick: () => void;
    text?: string;
    color?: string;
    className?: string;
}

const ShowMoreBtn = ({isActive, onClick, color, className, text = 'Show more'}:IShowMoreBtnProps):JSX.Element => {
    return (
        <div
            style={{color}}
            onClick={onClick}
            className={classNames(styles.showMoreBtn, 'btn-text', className, {
                [styles.active]: isActive,
            })}
        >{isActive ? 'Show less' : text}<AngleDown fill={color}/></div>
    );
};

export default ShowMoreBtn;