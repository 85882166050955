import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import 'normalize.css';
import Index from 'app';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';

Sentry.init({
    dsn: 'https://f1af1d0dc456fd579065a979b398e673@o4507328027885568.ingest.us.sentry.io/4507328039288832',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['https://dev.voicesurvey.co.uk/', /^https:\/\/yourserver\.io\/api/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <Index />
        </BrowserRouter>
    </Provider>
);

