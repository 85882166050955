import React from 'react';
import cx from 'classnames';
import sty from './Container.module.scss';

interface PropTypes {
  as?: 'section' | 'div';
  padding?: string;
  styles?: React.CSSProperties;
  className?: string;
  children: React.ReactNode;
  survey?: boolean;
}

const Container: React.FC<PropTypes> = ({
    as: Section = 'div',
    styles = {},
    className,
    children,
    survey,
    padding
}) => {
    return (
        <Section style={{ ...styles, padding}} className={cx(sty.container, className, {[sty.survey]: survey})}>
            {children}
        </Section>
    );
};

export default Container;
