import React, { useEffect, useState } from 'react';
import styles from '../Header.module.scss';
import Paths from '../../../consts/Paths';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import useWindowSize from '../../../hooks/useWindowSize';
import { md, sm } from '../../../consts/Breakpoints';

interface IStartVoiceBtnProps {
    className?: string;
    titleOption?: string;
}
const StartVoiceBtn = ({ className, titleOption }: IStartVoiceBtnProps): JSX.Element => {
    const navigate = useNavigate();
    const { width } = useWindowSize();
    const [buttonText, SetbuttonText] = useState('Start VOICE survey');
    useEffect(() => {
        if (width > md) {
            SetbuttonText('Start VOICE survey');
        } else {
            if (titleOption === 'starts' && width > sm) {
                SetbuttonText('Start survey');
            } else if (titleOption == 'starts' && width < sm) {
                SetbuttonText('Start');
            }
        }
    }, [][width]);

    return (
        <div className={classNames(styles.startVoiceBtn, className, 'btn-text')}>
            <button className='underlineStartBtn' onClick={() => navigate(Paths.consentForm)}>
                {buttonText}
            </button>
            <div className={styles.jaw}></div>
        </div>
    );
};

export default StartVoiceBtn;