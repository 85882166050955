import {ICheckCodeResponse, IContact, ICreateContactResponse,ICheckUpdate,ICheckUpdateResponse, IUpdateClient, ICheckCodeOrUrl, IContactStatus, IToken, IVerifyTokenResponse} from './types';
import BaseApi from '../index';
export default class ClientApi extends BaseApi{

    public createContact(data: IContact): Promise<ICreateContactResponse> {
        return this.baseApi.post('/Contact/Create', data).then(({ data }) => data);
    }

    public checkContact(data: ICheckCodeOrUrl): Promise<ICheckCodeResponse> {
        const payload: { code?: string; url?: string } = {};
        if (data.code)
            payload.code = data.code;
        else if (data.url)
            payload.url = data.url;
        return this.baseApi.post('/ClientContact/Check', payload).then(({ data }) => data);
    }

    public updateContactStatus(data: IContactStatus): Promise<ICheckUpdateResponse> {
        return this.baseApi.post('/Contact/UpdateStatus', data).then(({ data }) => data);
    }

    public updateClientContact(data: ICheckUpdate): Promise<ICheckUpdateResponse> {
        return this.baseApi.post('/ClientContact/Update', data).then(({ data }) => data);
    }

    public updateContact(data: IUpdateClient): Promise<ICheckUpdateResponse> {
        return this.baseApi.post('/Contact/Update', data).then(({ data }) => data);
    }

    public verifyRecaptcha(data: IToken): Promise<IVerifyTokenResponse> {
        return this.baseApi.post('/Recaptcha/Verify', data).then(({ data }) => data);
    }

    public getClientDetail(): Promise<any> {
        return this.baseApi.get('/ClientContact/get').then(({ data }) => data);
    }
}
