// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tag_tag__OkXwl {
  display: inline-flex;
  align-items: center;
  padding: 5px 15px;
  border-radius: 20px;
  margin-right: 10px;
  background-color: #c8d9cf;
}
.Tag_tag__OkXwl.Tag_blue__X5qfR {
  background-color: #4789d7;
}
.Tag_tag__OkXwl.Tag_orange__OmqU0 {
  background-color: #FFAE11;
}`, "",{"version":3,"sources":["webpack://./src/components/Tag/Tag.module.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAEA;EACE,oBAAA;EACA,mBAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;EACA,yBCUW;ADXb;AAGE;EACE,yBCaG;ADdP;AAIE;EACE,yBCMK;ADRT","sourcesContent":["@import \"../../styles/variables\";\n\n.tag{\n  display: inline-flex;\n  align-items: center;\n  padding: 5px 15px;\n  border-radius: 20px;\n  margin-right: 10px;\n  background-color: $waterGreen;\n\n  &.blue{\n    background-color: $blue;\n  }\n\n  &.orange{\n    background-color: $orange;\n  }\n}\n","$grid-breakpoints: (\r\n        xs: 350px,\r\n        ms: 426px,\r\n        sm: 576px,\r\n        md: 768px,\r\n        lg: 992px,\r\n        xl: 1200px,\r\n        xxl: 1440px,\r\n        xxxl: 1581px\r\n);\r\n\r\n$black: #161d1b;\r\n$gray: #bebeb576;\r\n$gray1: #6F7172;\r\n$gray2: #BEBEBE;\r\n$gray3: #E5E8EB;\r\n$grayBg: #E6E6E6;\r\n$steel: #d1d5db;\r\n$waterGreen: #c8d9cf;\r\n$white: #f4f7f6;\r\n$whiteBg: #f6f6f6;\r\n$orange: #FFAE11;\r\n$yellow: #e5de35;\r\n$slateBlue: #365479;\r\n$blue: #4789d7;\r\n$burntOrange: #b93d22;\r\n$green: #4a7b75;\r\n$seaGreen: #C8D9CF;\r\n$cream: #FAECD2;\r\n\r\n$lightOrange: #fff6e5;\r\n$lightBlue: #eaf2fa;\r\n$lightWaterGreen: #f0f5f2;\r\n$lightSlateBlue: #edf2f7;\r\n$lightSteel: #f1f2f4;\r\n$lightBurntOrange: #fbede9;\r\n$lightGreen: #eff5f5;\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag": `Tag_tag__OkXwl`,
	"blue": `Tag_blue__X5qfR`,
	"orange": `Tag_orange__OmqU0`
};
export default ___CSS_LOADER_EXPORT___;
