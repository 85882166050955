import React from 'react';
import {NavLink} from 'react-router-dom';
import AngleArrow from 'assets/icons/AngleArrow';
import styles from './SurveyHeader.module.scss';
import Button from 'components/Buttons';
import cx from 'classnames';
import useWindowSize from '../../hooks/useWindowSize';
import {md} from '../../consts/Breakpoints';
import ProjectLogo from '../../assets/icons/logo/ProjectLogo/ProjectLogo';
import ProjectLogoSm from '../../assets/icons/logo/ProjectLogo/ProjectLogoSm';
import cardsLogo from '../../assets/icons/Cards.png';

interface ISurveyHeaderProps {
    showBackButton?: boolean;
    openModal: () => void;
}
const SurveyHeader: React.FC<ISurveyHeaderProps> = ({showBackButton, openModal}) => {
    const params = new URLSearchParams(window.location.search);
    const info = params.get('info');
    const {width} = useWindowSize();
    return (
        <>
            <header className={styles.surveyHeader}>
                <nav className={styles.surveyNav}>
                    <div className={styles.logo}>
                        <div onClick={openModal}>
                            {width > md ? <ProjectLogo/> : <ProjectLogoSm/>}
                        </div>
                        {showBackButton && (
                            <NavLink
                                to="/survey/welcome"
                                className={cx(styles.back, 'btn-text')}
                            >
                                <AngleArrow /> Back to the survey
                            </NavLink>
                        )}
                    </div>
                    {
                        info ?
                            (
                                <div className={styles.logoHolder}>
                                    <img src={cardsLogo} alt='Leave Card Logo Image'/>
                                </div>
                            ):
                            ''
                    }
                    <Button onClick={openModal} transparent>
                        Leave
                    </Button>
                </nav>
            </header>
        </>
    );
};

export default SurveyHeader;
